.home {
    height: 100vh;
    width: 100vw;

    .topbar {
        width: 100%;
        height: 75px;

        padding: 0 30px 0 30px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: 24px;
            font-weight: bold;
        }
    }

    .mainarea {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        &.hash {
            justify-content: flex-start;
        }

        .text {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 10px;
            width: 90%;
            height: 60px;
            border-radius: 7px;

            padding: 0 10px 0 10px;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: "red";

        display: flex;
        align-items: center;
        justify-content: center;

        .in {
            height: 100%;
            display: flex;
            align-items: center;

            padding: 0 30px 0 30px;
        }
    }
}
